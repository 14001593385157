<template>
  <div class="flex gap-4 cursor-pointer" @click="onSelected">
    <div class="flex-none grid grid-cols-1 content-center">
      <img :src="$props.icon" alt="Icon" class="object-fill w-8 h-full" />
    </div>
    <div class="flex flex-col justify-items-start flex-shrink w-full">
      <span class="font-bold text-md sm:text-lg text-black">
        {{ $props.paymentName }}
      </span>
      <span class="text-xs sm:text-sm text-black">
        {{ $props.desc }}
      </span>
      <span
        class="font-bold text-sm sm:text-md text-black"
        v-if="$props.showSaldo"
      >
        Balance {{ $props.saldo | toCurrency }}
      </span>
    </div>
    <div class="w-20 h-full grid grid-cols-1 content-center justify-items-end">
      <div
        class="rounded-full h-7 w-7 border-4 border-yellow p-2 grid grid-cols-1 content-center justify-items-center"
      >
        <div
          class="rounded-full h-4 w-4"
          :class="{ 'bg-primary': $props.selected === true }"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    paymentName: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      required: true,
    },
    showSaldo: {
      type: Boolean,
      required: true,
    },
    saldo: {
      type: Number,
    },
    selected: {
      type: Boolean,
    },
  },
  data() {
    return {};
  },
  methods: {
    onSelected() {
      this.$emit("onSelectedPayment", this.$props.value);
    },
  },
};
</script>
