<template>
  <div>
    <Header />
    <div
      class="container mx-auto border border-grey-2 rounded-lg my-10 flex flex-wrap"
    >
      <aside class="md:h-screen top-0 p-4 w-full md:w-fit">
        <form id="form-filter">
          
        <div class="flex flex-col font-bold">
          <label class="block font-bold align-middle my-auto">Location :</label>
          <div
            class="bg-primary text-white font-bold py-1 px-4 border border-primary rounded-xl w-full grid text-center"
          >
            {{ $store.state.package.nama_kota }}
          </div>
        </div>
          <div class="mb-6" v-if="$route.params.slug == 'landadventure'">
            <label class="block mb-2 font-bold text-gray-900 dark:text-gray-300"
              >{{ textLabel }} Type</label
            >
            <div class="flex flex-col gap-2">
              <div
                class="bg-primary text-white cursor-pointer font-bold hover:bg-primary hover:text-white py-1 px-4 border border-primary rounded-xl flex flex-row gap-2 justify-center"
              >
                <div class="grid grid-cols-1 content-center" v-if="$route.params.trip == 'offroad'">Off Road</div>
                <div class="grid grid-cols-1 content-center" v-if="$route.params.trip == 'onroad'">On Road</div>
                <div class="grid grid-cols-1 content-center">
                  <font-awesome-icon
                    icon="fa-solid fa-circle-check"
                    class="text-yellow"
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </aside>
      <div class="flex flex-none md:flex-1 flex-col p-4 w-full">
        <div class="flex font-bold px-2">
          <label class="font-bold inline-block align-middle my-auto"
            >Select Package</label
          >
          <div class="flex flex-row gap-2 mx-2">
            <button
              type="button"
              class="bg-primary text-white font-bold py-1 px-4 border border-primary rounded-xl"
            >
              Day Trip
            </button>
            <button
              type="button"
              disabled
              class="bg-white text-grey-2 font-bold py-1 px-4 border border-grey-2 rounded-xl cursor-not-allowed"
            >
              Rental
            </button>
          </div>
        </div>
        <div class="py-4 px-2">
          <div class="md:grid md:grid-cols-4 gap-4 px-2">
            <div class="col-span-2 py-2">
              <div class="w-full">
                <span class="font-extrabold text-black text-lg">
                  Choose Date
                </span>
                <div class="section text-center w-full">
                  <div class="flex flex-col items-center mb-6 w-full">
                    <v-date-picker
                      ref="calendar"
                      is-expanded
                      v-model="formData.bookingDate"
                      :attributes="calAttr"
                      :disabled-dates="calDisable"
                      :minDate="minDate"
                      :maxDate="maxDate"
                      @dayclick="onDayClick"
                      @update:from-page="onMonthChange"
                      @update:to-page="onMonthChange"
                    />
                  </div>
                </div>
              </div>
                <div
                  class="rounded-md border-2 border-yellow-f6 h-10 text-sm font-semibold flex justify-center items-center"
                >
                  <span v-if="formData.bookingDate == null">Selected Date First</span>
                  <span v-else>Avaiable stock is {{ availableStock }} on {{ $moment(formData.bookingDate).format('Do MMMM') }}</span>
                </div>
              <div class="w-full mt-4">
                <span class="font-extrabold text-black text-lg">
                  Participant
                </span>
                <div class="w-full">
                  <div
                    class="border-b border-grey-3 my-1"
                    v-for="(det, detidx) in listDetailPackage"
                    :key="detidx"
                  >
                    <div class="px-4 flex justify-between">
                      <div class="grid grid-cols-1">
                        <span class="font-bold text-black">{{ det.nama }}</span>
                        <span class="font-bold text-primary">{{
                          det.harga | toCurrency
                        }}</span>
                      </div>
                      <div class="flex flex-row gap-2 items-center">
                        <button
                          type="button"
                          @click="minusQty(det)"
                          class="text-3xl"
                        >
                          -
                        </button>
                        <input
                          type="number"
                          name="qty"
                          class="box-border h-6 w-6 text-center border text-xl"
                          readonly
                          :value="det.qty"
                        />
                        <button
                          type="button"
                          @click="plusQty(detidx)"
                          class="text-3xl"
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-span-2 flex-col gap-2 text-sm text-left py-2">
              <div class="border border-grey-2 rounded-xl p-4">
                <div v-if="showPembayaran == 'subtotal'">
                  <div class="grid grid-cols-1">
                    <span
                      class="font-bold text-black text-lg"
                      v-if="totalQty > 0"
                    >
                      Base Rate
                    </span>
                    <div
                      v-for="(det2, det2idx) in $store.state.package.detail"
                      :key="det2idx"
                    >
                      <span class="text-black text-lg" v-if="det2.qty > 0">
                        {{ det2.nama }} : {{ det2.qty }} x
                        {{ det2.harga | toCurrency }}
                      </span>
                    </div>
                    <span
                      class="font-bold text-black text-lg"
                      v-if="formData.bookingDate != null"
                    >
                      Date
                    </span>
                    <span
                      class="text-black text-lg"
                      v-if="formData.bookingDate != null"
                    >
                      {{
                        $moment(formData.bookingDate).format(
                          "ddd, MMMM D, YYYY"
                        )
                      }}
                    </span>
                  </div>
                  <div class="flex my-4">
                    <span class="font-bold text-black text-lg">
                      Starting Time
                    </span>
                    <span class="flex flex-row gap-2 mx-4">
                      <button
                        type="button"
                        class="bg-primary hover:bg-primary text-white font-bold py-1 px-6 border border-grey-2 rounded-xl"
                      >
                        {{ $store.state.package.jam_mulai }}
                      </button>
                      <!-- <button type="button" class="bg-white hover:bg-primary text-grey-2 hover:text-white font-bold py-1 px-6 border border-primary rounded-xl">
                                        14:00
                                        </button> -->
                    </span>
                  </div>
                  <div class="w-full my-8 flex flex-row" v-if="ttlVocer > 0">
                    <div
                      class="cursor-pointer font-bold flex flex-row justify-between item-center pt-0.5 pb-1 px-5 md:px-10 mx-auto w-full"
                      :style="[voucherStyle]"
                      @click="showPembayaran = 'voucher'"
                    >
                      <div class="flex flex-row justify-center item-center pt-0.5 pb-1 w-full">
                        <div class="flex flex-col gap-0">
                          <span
                            class="text-xs md:text-base"
                            v-if="Object.keys(formData.voucher) == 0"
                            >We have some promo for you!</span
                          >
                          <span class="text-xs md:text-lg" v-else>{{
                            formData.voucher.judul
                          }}</span>
                          <span
                            class="text-xs md:text-sm"
                            v-if="Object.keys(formData.voucher) == 0"
                            >{{ ttlVocer }} voucher(s) available</span
                          >
                          <span class="text-xs md:text-sm" v-else-if="ttlVocer - 1 > 0"
                            >{{ ttlVocer - 1 }} voucher(s) available</span
                          >
                          <span class="text-xs md:text-sm" v-else>Show more voucher</span>
                        </div>
                      </div>
                      <span class="grid grid-cols-1 gap-0 content-center"
                        ><font-awesome-icon
                          icon="fa-solid fa-angle-right"
                          size="lg"
                          class="text-primary"
                      /></span>
                    </div>
                    <div
                      v-if="Object.keys(formData.voucher) != 0"
                      class="text-red-400 text-lg font-bold cursor-pointer"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-trash-can"
                        @click="formData.voucher = {}"
                      />
                    </div>
                  </div>
                  <div class="w-full flex flex-col gap-y-2">
                    <div
                      class="flex justify-between"
                      v-if="Object.keys(formData.voucher) != 0"
                    >
                      <span class="font-bold text-black text-lg">
                        Voucher
                      </span>
                      <span class="font-bold text-primary text-lg" v-if="formData.voucher.status_cashback != '1'">
                        - {{ potonganVoucher | toCurrency }}
                      </span>
                    </div>
                    <div
                      class="flex justify-between"
                      v-if="Object.keys(formData.voucher) != 0 && formData.voucher.status_cashback == 1"
                    >
                      <span class="font-bold text-black text-lg">
                        Cashback Balance
                      </span>
                      <span class="font-bold text-primary text-lg">
                        {{tripwepayVoucherCashback | toCurrency}}
                      </span>
                    </div>
                    <div class="flex justify-between" v-if="Object.keys(formData.voucher) != 0 && formData.voucher.status_cashback == 1">
                        <span class="font-bold text-black text-sm">
                            Cashback Point
                        </span>
                        <span class="font-bold text-primary text-sm">
                            {{pointVoucherCashback | toCurrency}}
                        </span>
                    </div>
                    <div class="flex justify-between">
                      <span class="font-bold text-black text-lg">
                        Subtotal
                      </span>
                      <span class="font-bold text-primary text-lg">
                        {{ subtotal | toCurrency }}
                      </span>
                    </div>
                    <div
                      class="flex justify-between"
                      v-if="formData.tripwepoints != 0"
                    >
                      <span class="font-bold text-black text-lg">
                        TripwePoints
                      </span>
                      <span class="font-bold text-primary text-lg">
                        - {{ formData.tripwepoints | toCurrency }}
                      </span>
                    </div>
                    <div class="flex justify-between">
                      <span class="font-extrabold text-black text-xl">
                        Total Payment
                      </span>
                      <span class="font-extrabold text-primary text-xl">
                        {{ totalpayment | toCurrency }}
                      </span>
                    </div>
                    <div
                      class="flex flex-row justify-between"
                      v-if="isLimitMaxCC"
                    >
                      <span class="font-bold text-red-600 text-md">
                        *The maximum payment using a credit card is 100 million
                        rupiah
                      </span>
                    </div>
                  </div>
                  <div class="flex mt-4">
                    <button
                      class="bg-primary text-white font-bold py-2 border border-grey-2 rounded-xl text-xl mx-auto px-10"
                      @click="showPembayaran = 'pembayaran'"
                    >
                      Payment Method
                    </button>
                  </div>
                </div>
                <div v-else-if="showPembayaran == 'voucher'">
                  <div class="text-center m-auto">
                    <span class="font-bold text-black text-xl">
                      Use Voucher
                    </span>
                    <span
                      class="float-right cursor-pointer"
                      @click="showPembayaran = 'subtotal'"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-close"
                        size="lg"
                        class="text-gray"
                      />
                    </span>
                  </div>
                  <div class="w-full md:px-8">
                    <div
                      class="flex flex-row justify-between p-4 text-white mt-6"
                      :style="voucherGreenStyle"
                    >
                      <div class="flex flex-col">
                        <span class="font-bold text-sm">
                          {{ rekomendasiVoucher.judul }}
                        </span>
                        <span
                          class="text-sm"
                          v-if="rekomendasiVoucher.satuan == 'PERSEN'"
                        >
                          {{ rekomendasiVoucher.nominal }}%
                        </span>
                        <span class="text-sm" v-else>
                          {{ rekomendasiVoucher.nominal | toCurrency }}
                        </span>
                      </div>
                      <div class="felx items-center">
                        <button
                          type="button"
                          class="border border-white rounded-xl px-4 py-2"
                          @click="selectVoucher(rekomendasiVoucher)"
                        >
                          Select
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="border-b-8 border-grey my-4"></div>
                  <div class="w-full md:px-8">
                    <span class="text-sm font-bold">Add Voucher</span>
                    <div class="md:flex flex-row gap-8">
                      <input
                        v-model="kodeVoucher"
                        placeholder="XXXXXX"
                        class="w-full border-b-2 border-primary p-2"
                      />
                      <button
                        type="button"
                        class="border border-white font-bold rounded-xl px-12 py-3 bg-primary text-white mt-2 md:mt-0"
                        @click="addVoucher"
                      >
                        Add
                      </button>
                    </div>
                  </div>
                  <div class="border-b-8 border-grey my-4"></div>
                  <div class="w-full md:px-8" v-if="listVoucher.length > 0">
                    <div class="flex flex-row gap-4 justify-between">
                      <div class="flex flex-row gap-4">
                        <div class="grid grid-cols-1 content-center">
                          <img :src="iconVoucher" alt="" />
                        </div>
                        <div class="grid grid-cols-1">
                          <span class="text-base md:text-xl font-bold">My Voucher</span>
                          <span class="text-base text-gray-500"
                            >You can only select 1 voucher</span
                          >
                        </div>
                      </div>
                      <div class="grid grid-cols-1 content-center">
                        <font-awesome-icon
                          icon="fa-solid fa-angle-down"
                          size="lg"
                          class="text-primary"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:px-8 my-4 overflow-y-auto h-36">
                    <div class="flex flex-col gap-4">
                      <div
                        class="border rounded-xl py-2 px-4 border-black hover:bg-primary hover:text-white"
                        :class="{
                          'bg-primary text-white':
                            Object.keys(formData.voucher) != 0 &&
                            formData.voucher.id_voucher == vocer.id_voucher,
                        }"
                        v-for="(vocer, index) in listVoucher"
                        :key="index"
                      >
                        <div class="flex flex-row justify-between">
                          <div
                            class="flex flex-col gap cursor-pointer"
                            @click="selectVoucher(vocer)"
                          >
                            <!-- <span class="font-bold text-lg text-primary">{{ vocer.kode }}</span> -->
                            <span class="font-bold text-lg">{{
                              vocer.judul
                            }}</span>
                            <span
                              class="text-base text-grey-500 hover:text-white"
                              >Expired date:
                              {{
                                $moment(vocer.tglz).format("DD MMMM YYYY")
                              }}</span
                            >
                          </div>
                          <div
                            class="grid grid-cols-1 justify-between content-end cursor-pointer text-sm md:text-lg"
                          >
                            <span
                              class="font-bold"
                              v-if="vocer.satuan == 'PERSEN'"
                              >{{ vocer.nominal }}%</span
                            >
                            <span class="font-bold" v-else>{{
                              vocer.nominal | toCurrency
                            }}</span>
                            <span
                              class="text-base hover:text-white"
                              @click="showDetailVoucher(vocer)"
                              >Detail</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="showPembayaran == 'pembayaran'">
                  <div class="grid grid-cols-1">
                    <span
                      class="font-bold text-black text-lg"
                      v-if="totalQty > 0"
                    >
                      Base Rate
                    </span>
                    <div
                      v-for="(det2, det2idx) in $store.state.package.detail"
                      :key="det2idx"
                    >
                      <span class="text-black text-lg" v-if="det2.qty > 0">
                        {{ det2.nama }} : {{ det2.qty }} x
                        {{ det2.harga | toCurrency }}
                      </span>
                    </div>
                    <span
                      class="font-bold text-black text-lg"
                      v-if="formData.bookingDate != null"
                    >
                      Date
                    </span>
                    <span
                      class="text-black text-lg"
                      v-if="formData.bookingDate != null"
                    >
                      {{
                        $moment(formData.bookingDate).format(
                          "ddd, MMMM D, YYYY"
                        )
                      }}
                    </span>
                  </div>
                  <div class="flex my-4">
                    <span class="font-bold text-black text-lg">
                      Starting Time
                    </span>
                    <span class="flex flex-row gap-2 mx-4">
                      <button
                        type="button"
                        class="bg-primary hover:bg-primary text-white font-bold py-1 px-6 border border-grey-2 rounded-xl"
                      >
                        {{ $store.state.package.jam_mulai }}
                      </button>
                      <!-- <button type="button" class="bg-white hover:bg-primary text-grey-2 hover:text-white font-bold py-1 px-6 border border-primary rounded-xl">
                                        14:00
                                        </button> -->
                    </span>
                  </div>
                  <div class="w-full flex flex-col gap-y-2">
                    <div
                      class="flex justify-between"
                      v-if="Object.keys(formData.voucher) != 0"
                    >
                      <span class="font-bold text-black text-lg">
                        Voucher
                      </span>
                      <span class="font-bold text-primary text-lg">
                        - {{ potonganVoucher | toCurrency }}
                      </span>
                    </div>
                    <div class="flex justify-between">
                      <span class="font-bold text-black text-lg">
                        Subtotal
                      </span>
                      <span class="font-bold text-primary text-lg">
                        {{ subtotal | toCurrency }}
                      </span>
                    </div>
                    <div
                      class="flex justify-between"
                      v-if="formData.tripwepoints != 0"
                    >
                      <span class="font-bold text-black text-lg">
                        TripwePoints
                      </span>
                      <span class="font-bold text-primary text-lg">
                        - {{ formData.tripwepoints | toCurrency }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="flex flex-row justify-between"
                    v-if="formData.paymentMode == 1"
                  >
                    <span class="font-bold text-black text-xl">
                      Credit Card admin fee (3.5%)
                    </span>
                    <span class="font-bold text-primary text-lg">
                      {{ crediCardAdminFee | toCurrency }}
                    </span>
                  </div>
                  <div class="flex flex-row justify-between">
                    <span class="font-extrabold text-black text-xl">
                      Total Payment
                    </span>
                    <span class="font-extrabold text-primary text-lg">
                      {{ totalpayment | toCurrency }}
                    </span>
                  </div>
                  <div
                    class="flex flex-row justify-between"
                    v-if="isLimitMaxCC"
                  >
                    <span class="font-bold text-red-600 text-md">
                      *The maximum payment using a credit card is 100 million
                      rupiah
                    </span>
                  </div>
                  <div class="font-bold text-primary text-lg mt-6 mb-2">
                    Payment Method
                  </div>
                  <div class="w-full grid grid-col gap-4">
                    <CardPaymentMethod
                      @onSelectedPayment="selectPayment"
                      :value="2"
                      :icon="iconBalance"
                      paymentName="Balance"
                      desc=""
                      :saldo="$store.state.saldo.SaldoAktif"
                      :showSaldo="true"
                      :selected="formData.paymentMode == 2"
                    />
                    <!-- <CardPaymentMethod @onSelectedPayment="selectPayment" :value="0" :icon="iconBni" paymentName="Transfer Bank - BNI" desc="Transfer funds via ATM or mBanking to BNI virtual account" :showSaldo="false" :selected="(formData.paymentMode == 0)" /> -->
                    <!-- <CardPaymentMethod
                      @onSelectedPayment="selectPayment"
                      :value="4"
                      :icon="iconBca"
                      paymentName="Transfer Bank - BCA"
                      desc="Transfer funds via ATM or mBanking to BCA virtual account"
                      :showSaldo="false"
                      :selected="formData.paymentMode == 4"
                      v-show="!statusMerchant"
                    /> -->
                    <!-- <CardPaymentMethod
                      @onSelectedPayment="selectPayment"
                      :value="5"
                      :icon="iconPermata"
                      paymentName="Transfer Bank - Permata"
                      desc="Transfer funds via ATM or mBanking to Permata virtual account"
                      :showSaldo="false"
                      :selected="formData.paymentMode == 5"
                      v-show="!statusMerchant"
                    />
                    <CardPaymentMethod
                      @onSelectedPayment="selectPayment"
                      :value="6"
                      :icon="iconBri"
                      paymentName="Transfer Bank - BRI"
                      desc="Transfer funds via ATM or mBanking to BRI virtual account"
                      :showSaldo="false"
                      :selected="formData.paymentMode == 6"
                      v-show="!statusMerchant"
                    /> -->
                    <!-- <CardPaymentMethod
                      @onSelectedPayment="selectPayment"
                      :value="1"
                      :icon="iconCC"
                      paymentName="Credit Card"
                      desc="Transfer funds via Credit Card"
                      :showSaldo="false"
                      :selected="formData.paymentMode == 1"
                      v-show="!isLimitMaxCC && !statusMerchant"
                    />
                    <CardPaymentMethod
                      @onSelectedPayment="selectPayment"
                      :value="41"
                      :icon="iconCC"
                      paymentName="Bank Transfer"
                      desc="Transfer funds via ATM or mBanking"
                      :showSaldo="false"
                      :selected="formData.paymentMode == 41"
                      v-show="statusMerchant"
                    /> -->
                    <CardPaymentMethod
                      @onSelectedPayment="selectPayment"
                      :value="99"
                      :icon="iconOther"
                      paymentName="Other Method"
                      desc=""
                      :showSaldo="false"
                      :selected="formData.paymentMode == 99"
                    />
                  </div>
                  <div class="flex mt-8">
                    <button
                      class="bg-white underline text-primary font-bold py-1 px-10 border border-grey-2 rounded-xl text-md mx-auto"
                      type="button"
                      @click="handleCancelation"
                    >
                      Cancelation Policy
                    </button>
                  </div>
                  <div class="flex mt-4">
                    <button
                      class="bg-primary text-white font-bold py-2 border border-grey-2 rounded-xl text-xl mx-auto px-10"
                      type="button"
                      @click="handleBook"
                    >
                      Booking Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <modal-pin ref="modalPin"></modal-pin>
    <cancelation-policy-dialog
      ref="cancelationPolicyDialog"
    ></cancelation-policy-dialog>
    <detail-voucher ref="detailVoucher"></detail-voucher>
    <modal-open-cc ref="modalOpenCc"></modal-open-cc>
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";
import CardPaymentMethod from "../components/CardPaymentMethod";
import Vue from "vue";
import L from "leaflet";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";

export default {
  name: "CheckoutPaket",
  components: {
    Header,
    Footer,
    CardPaymentMethod,
    LMap,
    LTileLayer,
    LMarker,
    ModalPin: () => import("@/components/layout/ModalPin.vue"),
    CancelationPolicyDialog: () =>
      import("@/components/layout/CancelationPolicyDialog.vue"),
    DetailVoucher: () => import("@/components/layout/DetailVoucher.vue"),
    ModalOpenCc: () => import("@/components/layout/ModalOpenCc.vue"),
  },
  data: () => ({
    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    attribution:
      '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    mapOptions: {
      zoomSnap: 0.5,
    },
    modalPin: false,
    availableStock: 0,
    activeTab: 0,
    selectedIdWilayah: null,
    showPembayaran: "subtotal",
    kodeVoucher: "",
    listVoucher: [],
    listKota: [],
    listDock: [],
    listDetailPackage: [],
    rekomendasiVoucher: {},
    statusMerchant: false,
    formData: {
      bulantahun: "",
      tokenPayment: "",
      link: "",
      totalQty: 0,
      subtotal: 0,
      tripwepoints: 0,
      bookingDate: null,
      paymentMode: null,
      pin: null,
      voucher: {},
      responseCreateTagihan: {},
    },
    iconOther: require("@/assets/logo-other-payment.png"),
    iconCC: require("@/assets/cc-icon.png"),
    iconBni: require("@/assets/bni-icon.png"),
    iconBca: require("@/assets/bca-icon.png"),
    iconBri: require("@/assets/bri-icon.png"),
    iconPermata: require("@/assets/permata-icon.png"),
    iconTripwe: require("@/assets/tripwe-icon.png"),
    iconBalance: require("@/assets/logo-balance.png"),
    iconVoucher: require("@/assets/icons/icon-voucher.png"),
    voucherBg: require("@/assets/voucher.png"),
    voucherStyle: {
      backgroundImage: "url('/voucher.png')",
      backgroundSize: "100% 140%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
    voucherGreenStyle: {
      backgroundImage: "url('/voucher_green.png')",
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
    },
    attrs: [],
    disabledDates: [],
  }),
  computed: {
    sts() {
      var sts = "2";
      if (this.$route.params.trip == "offroad") {
        sts = "3";
      } else if (this.$route.params.trip == "onroad") {
        sts = "4";
      } else if (this.$route.params.slug == "skyadventure") {
        sts = "5";
      }
      return sts;
    },
    ttlVocer(){
      return this.listVoucher.length;
    },
    isLimitMaxCC() {
      if (this.totalpayment > 100000000) {
        return true;
      }
      return false;
    },
    minDate() {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    maxDate() {
      var date = new Date();
      date.setDate(date.getDate() + 1);
      date.setMonth(date.getMonth() + 3);
      return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    },
    potonganVoucher() {
      var potongan = 0;
      if (Object.keys(this.formData.voucher) != 0) {
        if (this.formData.voucher.satuan == "PERSEN") {
          potongan = (this.subtotal * this.formData.voucher.nominal) / 100;
          if (potongan > parseInt(this.formData.voucher.maximal, 10)) {
            potongan = parseInt(this.formData.voucher.maximal, 10);
          }
        } else {
          potongan = this.formData.voucher.nominal;
        }
      }
      return potongan;
    },
    tripwepayVoucherCashback(){
        var potongan = 0;
        var twpay = 0;
        if(Object.keys(this.formData.voucher) != 0){
            if(this.formData.voucher.status_cashback == '1'){
                if(this.formData.voucher.satuan == 'PERSEN'){
                    potongan = this.subtotal * this.formData.voucher.nominal/100;
                    if(potongan > parseInt(this.formData.voucher.maximal,10)){
                        potongan = parseInt(this.formData.voucher.maximal,10);
                    }
                    var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                    twpay = hasilpersen;
                }else{
                    potongan = this.formData.voucher.nominal;
                    var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                    twpay = hasilpersen;
                }
            }
        }
        return twpay;
    },
    pointVoucherCashback(){
        var potongan = 0;
        var point = 0;
        if(Object.keys(this.formData.voucher) != 0){
            if(this.formData.voucher.status_cashback == '1'){
                if(this.formData.voucher.satuan == 'PERSEN'){
                    potongan = this.subtotal * this.formData.voucher.nominal/100;
                    if(potongan > parseInt(this.formData.voucher.maximal,10)){
                        potongan = parseInt(this.formData.voucher.maximal,10);
                    }
                    var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                    point = potongan-hasilpersen;
                }else{
                    potongan = this.formData.voucher.nominal;
                    var hasilpersen = potongan * this.formData.voucher.persencashback/100;
                    point = potongan-hasilpersen;
                }
            }
        }
        return point;
    },
    calAttr() {
      return this.attrs;
    },
    calDisable() {
      return this.disabledDates;
    },
    subtotal() {
      var subttl = 0;
      for (var x in this.listDetailPackage) {
        if (Object.hasOwnProperty.call(this.listDetailPackage, x)) {
          const element = this.listDetailPackage[x];
          subttl += parseInt(element.qty, 10) * element.harga;
        }
      }
      return subttl;
    },
    crediCardAdminFee() {
      var total = this.subtotal - this.potonganVoucher;
      if (this.formData.paymentMode == 1) {
        return (total * 3.5) / 100;
      }
      return 0;
    },
    totalpayment(){
        if(this.formData.voucher.status_cashback == "1"){
            return this.subtotal + this.crediCardAdminFee;
        }else{
            return this.subtotal - this.potonganVoucher + this.crediCardAdminFee;
        }
    },
    totalQty() {
      var totalQty = 0;
      for (var x in this.listDetailPackage) {
        if (Object.hasOwnProperty.call(this.listDetailPackage, x)) {
          const element = this.listDetailPackage[x];
          totalQty += parseInt(element.qty, 10);
        }
      }
      this.formData.totalQty = totalQty;
      return totalQty;
    },
    textLabel() {
      let str = "";
      switch (this.$route.params.slug) {
        case "wateradventure":
          str = "Watersport";
          break;
        case "landadventure":
          str = "Land Adventure";
          break;
        case "skyadventure":
          str = "Sky Adventure";
          break;

        default:
          str = "Watersport";
          break;
      }
      return str;
    },
  },
  created() {
    this.selectedIdWilayah = this.$store.state.idWilayah;
    this.listDetailPackage = this.$store.state.package.detail.map(detail =>Object.assign({}, detail, { qty: 0 }));
    this.countVoucher();
    this.loadVoucher();
    this.loadRekomenVoucher();
    this.loadCity();
    this.checkStatusMerchant();
    var now = this.$moment();
    this.formData.tokenPayment =
      "TWIP" + now.format("YYYYMMDDHHmmss") + this.randomStr(1, 9999);
    this.formData.bulantahun = now.format("YYYYMMHH");
  },
  methods: {
    onDayClick() {
      var date = this.$moment(this.formData.bookingDate);
      this.formData.bulantahun = date.format("YYYY-MM-DD");
      this.getKalender();
    },
    addVoucher() {
      var filteredVoucher = this.listVoucher.filter(
        (val) => val.kode_voucher == this.kodeVoucher
      );
      if (filteredVoucher.length == 1) {
        this.selectVoucher(filteredVoucher[0]);
        this.kodeVoucher = "";
      } else {
        this.$toasted.global.error("Voucher not found");
      }
    },
    onMonthChange(page) {
      // var mt = page.month.toString();
      // if(page.month < 10){
      //     mt = '0'+mt;
      // }
      // this.formData.bulantahun = page.year.toString()+mt;
      // this.getKalender();
    },
    async handleCancelation() {
      await this.$refs.cancelationPolicyDialog.open({
        title: "Cancelation Policy",
        button: "Close",
      });
    },
    randomStr(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    async handleBook() {
      let ttlqty = 0;
      for (const x in this.listDetailPackage) {
        if (Object.hasOwnProperty.call(this.listDetailPackage, x)) {
          const el = this.listDetailPackage[x];
          ttlqty += parseInt(el.qty);
        }
      }
      if (ttlqty <= 0) {
        this.$toasted.global.error("Please select participant first");
        return;
      }
      if (
        this.formData.bookingDate == null ||
        this.formData.bookingDate == ""
      ) {
        this.$toasted.global.error("Please select date first");
        return;
      }
      if (
        this.formData.paymentMode == null ||
        this.formData.paymentMode == ""
      ) {
        this.$toasted.global.error("Please select payment mode first");
        return;
      }
      if (this.formData.paymentMode == 1 && this.totalpayment > 100000000) {
        this.$toasted.global.error(
          "The maximum payment using a credit card is 100 million"
        );
        return;
      }
      if (
        this.formData.paymentMode == 1 &&
        (this.$store.state.user.email == null ||
          this.$store.state.user.email == "")
      ) {
        this.$toasted.global.error("Add email address first");
        return;
      }
      if (this.formData.paymentMode == 2) {
        // var valid = await this.validasiSaldo();
        // if (!valid) {
        //   return;
        // }
        const confirmed = await this.$refs.modalPin.open({
          button: "OK",
        });
        if (confirmed != null) {
          this.formData.pin = confirmed;
          this.createInvoice();
        }
        return;
      }
      this.createInvoice();
      return;
    },
    selectVoucher(vocer) {
      this.formData.voucher = vocer;
      this.$toasted.global.success("Voucher applied");
      this.showPembayaran = "subtotal";
    },
    async showDetailVoucher(detail) {
      var res = await this.$refs.detailVoucher.open({ data: detail });
      if (res) {
        this.selectVoucher(detail);
      }
    },
    selectPayment(val) {
      this.formData.paymentMode = val;
    },
    minusQty(det) {
      if (det?.qty === null || isNaN(det?.qty)) {
        det.qty = 0;
      }
      
      if (det.qty > 0) {
        det.qty--;
      } else {
        det.qty = 0;
      }
      // this.formData.bookingDate = null;
      // this.$toasted.global.warning("Please select date again");
      if (this.formData.bookingDate != null) {
        this.getKalender();
      }
      this.loadVoucher();
      this.loadRekomenVoucher();
      this.countVoucher();
    },
    plusQty(idx) {
      // if (det?.qty === null || isNaN(det?.qty)) {
      //   det.qty = 0;
      // }
      this.listDetailPackage[idx].qty++;
      // det.qty++;
      if (this.formData.bookingDate != null) {
        this.getKalender();
      }
      this.loadVoucher();
      this.loadRekomenVoucher();
      this.countVoucher();
    },
    valueQty(det) {
      return det?.qty || 0;
    },
    async checkStatusMerchant() {
      const res = await this.$http.post(
        `${this.$apiTripweWeb}/config/checkKhusus`,
        {
          idtoko: this.$store.state.package.id_toko,
        }
      );
      if (res.data.status == "200") {
        this.statusMerchant = res.data.response;
      }
    },
    async loadVoucher() {
      const res = await this.$http.get(`${this.$apiTripweWeb}/voucher/list`, {
        params: {
          store: this.$store.state.package.id_toko,
          category: this.sts,
          price: this.totalpayment
      }});
      if (res.data.status == 200) {
        this.listVoucher = res.data.data;
      }
    },
    async loadRekomenVoucher() {
      const res = await this.$http.get(`${this.$apiTripweWeb}/voucher/list/recommended`, {
        params: {
          store: this.$store.state.package.id_toko,
          category: this.sts,
          price: this.totalpayment
        }
      });
      if (res.data.status == 200) {
        this.rekomendasiVoucher = res.data.data;
      }
    },
    async countVoucher() {
      // await Vue.axios({
      //   method: "post",
      //   url: `${this.$apiSaeboTWV2}/ListOrderV3`,
      //   data: {
      //     id: "UATVCREDEEMCOUNT",
      //     kodecustomer: this.$store.state.user.id_user,
      //     toko: this.$store.state.package.id_toko,
      //     status: this.sts,
      //     hargamin: this.totalpayment,
      //     limit: "",
      //     cari: "",
      //   },
      // }).then((response) => {
      //   this.ttlVocer = response.data[0].CONT;
      //   return;
      // });
    },
    loadCity: function () {
      var bodyFormData = new FormData();
      bodyFormData.append("txtLat", "");
      bodyFormData.append("txtLong", "");
      bodyFormData.append("txtLimit", "50");
      bodyFormData.append("txtJenis", "s");
      var url = `${this.$apiTripwe}/ic/jetski-android-new/list_wilayah_terdekat_v19.php`;
      if (
        this.$route.params.slug == "landadventure" &&
        (this.$route.params.trip == "offroad" ||
          this.$route.params.trip == "onroad")
      ) {
        url = `${this.$apiTripwe}/ic/jetski-android-new/list_wilayah_terdekat_offroad_v16.php`;
        bodyFormData.append("txtFilter", "d");
      } else if (this.$route.params.slug == "skyadventure") {
        url = `${this.$apiTripwe}/ic/jetski-android-new/list_wilayah_terdekat_sky_v24.php`;
        bodyFormData.append("txtFilter", "d");
      } else {
        bodyFormData.append("txtFilter", "a");
      }

      Vue.axios({
        method: "post",
        url: url,
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        this.listKota = response.data;
        return;
      });
    },
    async getKalender() {
      this.$store.commit("setOverlayLoading", true);
      var url = `${this.$apiTripweWeb}/calendars/safari/jetski`;
      if (this.$route.params.trip == "offroad") {
        url = `${this.$apiTripweWeb}/calendars/safari/offroad`;
      } else if (this.$route.params.trip == "onroad") {
        url = `${this.$apiTripweWeb}/calendars/safari/onroad`;
      } else if (this.$route.params.trip == "sky") {
        url = `${this.$apiTripweWeb}/calendars/safari/sky`;
      }

      try {
        const response = await this.$http.post(url, {
          package_id: this.$store.state.package.id,
          date: this.formData.bulantahun,
          quantity: this.totalQty > 0 ? this.totalQty : 1,
        });
        // var newData = this.attrs;
        // var newData2 = this.disabledDates;
        if (response.data.code == "20000") {
          if (response.data.data.is_open != true) {
            this.formData.bookingDate = null;
            throw "Merchant is closed";
          }
          if (response.data.data.is_available != true) {
            // var date = this.$moment(this.formData.bookingDate);
            // this.attrs = newData;
            // this.disabledDates = newData2;
            // newData.push(
            //     {
            //         // key: tanggal,
            //         highlight: 'red',
            //         popover: {
            //             label: 'Not Available',
            //         },
            //         dates: date.toDate(),
            //     }
            // );
            // newData2.push({
            //     start: date.toDate(),
            //     end: date.toDate()
            // });
            this.formData.bookingDate = null;
            throw "Booked unit amount exceeding available unit";
          }
          
          this.availableStock = response.data.data.stock;
          // for (const key in response.data.data) {
          //     if (Object.hasOwnProperty.call(response.data.data, key)) {
          //         const element = response.data.data[key];
          //         var today = new Date();
          //         var date = this.$moment(element.tanggal);
          //         if(element.ada == 'tidak' && date.isAfter(today)){
          //             newData.push(
          //                 {
          //                     // key: tanggal,
          //                     // highlight: 'gray',
          //                     popover: {
          //                         label: 'Sold Out',
          //                     },
          //                     dates: date.toDate(),
          //                 }
          //             );
          //             newData2.push({
          //                 start: date.toDate(),
          //                 end: date.toDate()
          //             });
          //         }else if(element.ada == 'ada' && date.isAfter(today)){
          //             newData.push(
          //                 {
          //                     popover: {
          //                         label: 'Available Stok : ' + element.stok,
          //                     },
          //                     dates: date.toDate(),
          //                 }
          //             );
          //         }
          //     }
          // }
          // this.attrs = newData;
          // this.disabledDates = newData2;
        }
        return;
      } catch (error) {
        this.$toasted.global.error(error);
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async validasiSaldo() {
      this.$store.commit("setOverlayLoading", true);
      var resSaldo = await this.$http.get(
        `${this.$apiTripweWeb}/tripwepay/balance`
      );

      this.$store.commit("setOverlayLoading", false);
      this.$store.commit("setSaldo", resSaldo.data.response);
      if (this.$store.state.saldo.SaldoAktif < this.totalpayment) {
        this.$toasted.global.error("TripwePay amount less than total payment");
        this.$router.push({
          name: "Topup",
        });
        return false;
      }
      return true;
    },
    async createInvoice() {
      this.$store.commit("setOverlayLoading", true);
      try {
        var curDate = new Date();
        var trip = "safari";
        var kodepaket = "JS";
        if (this.$route.params.trip == "offroad") {
          kodepaket = "OFS";
          trip = this.$route.params.trip;
        } else if (this.$route.params.trip == "onroad") {
          kodepaket = "ONS";
          trip = this.$route.params.trip;
        } else if (this.$route.params.slug == "skyadventure") {
          kodepaket = "SKY";
          trip = this.$route.params.trip;
        }

        var nobukti =
          kodepaket +
          this.$moment(curDate).format("MDk") +
          "" +
          Math.floor(Math.random() * 100);

        var jam_awal_f = this.$store.state.package.jam_mulai.split(":").shift();
        var jam_awal = jam_awal_f + ":00";
        var jam_akhir = jam_awal;
        var tgl_akhir = this.$moment(this.formData.bookingDate).format(
          "YYYY-MM-DD"
        );
        if (this.$store.state.package.satuan == "Hours") {
          jam_akhir =
            parseInt(jam_awal_f) + parseInt(this.$store.state.package.durasi);
          if(jam_akhir < 10){
            jam_akhir = '0' + jam_akhir.toString() + ":00";
          }else{
            jam_akhir = jam_akhir.toString() + ":00";
          }
        } else {
          tgl_akhir = this.$moment(this.formData.bookingDate)
            .add(parseInt(this.$store.state.package.durasi), "days")
            .format("YYYY-MM-DD");
        }

        var daftarpesanan = "";
        var arrpesanan = [];
        for (const key in this.listDetailPackage) {
          if (
            Object.hasOwnProperty.call(this.listDetailPackage, key)
          ) {
            const element = this.listDetailPackage[key];
            if (element.qty > 0) {
              var curpsn =
                '{"id_paket":' +
                '"' +
                element.id +
                '"' +
                ',"seat":' +
                '"' +
                element.seat +
                '"' +
                ',"qtyunit":' +
                '"' +
                element.qty +
                '"' +
                "}";
                arrpesanan.push(curpsn);
            }
          }
        }
        daftarpesanan = arrpesanan.join(",");
        var datapost = {
          nobukti: nobukti,
          pin: this.formData.pin,
          id_paket: this.$store.state.package.id,
          kodecustomer: this.$store.state.user.id_user,
          vauser: this.$store.state.user.va,
          toko: this.$store.state.package.id_toko,
          vatoko: this.$store.state.package.va,
          tgl: this.$moment(this.formData.bookingDate).format("YYYY-MM-DD"),
          tgl_akhir: tgl_akhir,
          jam_awal: jam_awal,
          jam_akhir: jam_akhir,
          qtyjam: "",
          voucher: this.formData.voucher.id_voucher,
          cc: this.formData.paymentMode,
          daftarpesan: "[" + daftarpesanan + "]",
        };

        var url = `${this.$apiTripweWebV2}/transactions/create`;
        if(this.statusMerchant){
          url = `${this.$apiTripweWeb}/tagihan/create/external`;
        }
        const response = await this.$http.post(
          url,
          datapost
        );
        var res = response.data;
        if (res.status == "200") {
          this.formData.responseCreateTagihan = res.response;
          this.formData.link = "";
          if (this.formData.paymentMode == 1 || this.formData.paymentMode == 99) {
            this.formData.link = res.data.redirect_url;
            this.$store.commit("setOverlayLoading", false);
            await this.$refs.modalOpenCc.open({ link: this.formData.link });
            // this.handleCC();
            this.$router.replace({
              name: "TransactionDetail",
              params: {
                nobukti: res.data.reference_no,
              },
            });
          } else {
            this.$router.replace({
              name: "TransactionDetail",
              params: {
                nobukti: res.data.reference_no,
              },
            });
          }
        } else if (
          response.status == "400" &&
          response.message.includes("Email")
        ) {
          throw "Add email address first";
        } else if(response.data.status == '40001'){
          this.handleBook();
          throw response.data.message;
        } else if(response.data.status == '40002'){
          this.$router.push({
            name: "Topup",
          });
          throw response.data.message;
        } else {
          throw response.data.message;
        }
      } catch (error) {
        this.$toasted.global.error(
          error
        );
        // "Failed to booking, please check your connection and try again later"
      } finally {
        this.$store.commit("setOverlayLoading", false);
      }
    },
    async handleCC() {
      var nWdw = window.open(this.formData.link, "_blank").focus();
      // if(nWdw.closed){
      //     this.$router.push({
      //         name: 'PageFrame',
      //         params: {
      //             formData: this.formData
      //         }
      //     });
      // }
      // return;
    },
    async payC2Chold() {
      this.$store.commit("setOverlayLoading", true);
      var datapost = {
        customer_name: this.$store.state.user?.nama ?? "",
        payment_amount: this.formData.responseCreateTagihan?.TOTALBAYAR ?? 0,
        virtual_account: this.$store.state.package.va, // VA Tenant
        trx_id: this.formData.responseCreateTagihan?.KODE ?? "",
        description: "Payment",
        vapermata: this.$store.state.user.va, // VA User
        token: this.formData.tokenPayment,
        app: "TRIPWEDEV",
        client_id: "WEBAPPS",
      };

      var res = await this.$http.post(
        `${this.$apiTripweWeb}/tagihan/hold`,
        datapost
      );
      this.$store.commit("setOverlayLoading", false);
      if (res.data.status == "200") {
        var resSaldo = await this.$http.get(
          `${this.$apiTripweWeb}/tripwepay/balance`
        );
        this.$store.commit("setSaldo", resSaldo.data.response);

        var trip = "safari";
        if (this.$route.params.trip == "offroad") {
          trip = this.$route.params.trip;
        } else if (this.$route.params.trip == "onroad") {
          trip = this.$route.params.trip;
        }
        this.$router.replace({
          name: "TransactionDetail",
          params: {
            nobukti: this.formData.responseCreateTagihan?.KODE,
          },
        });
      } else {
      }
    },
  },
};
</script>
